




























































import { Component, Vue } from "vue-property-decorator";
import AuthModule from "@/store/modules/Auth";

@Component
export default class Dashboard extends Vue {
  nav = false;
  group = null;

  logout(): void {
    AuthModule.removeLoggedIn();
    this.$router.push({
      name: "Login",
    });
  }

  get getTitle(): string {
    switch (this.$route.name) {
      case "dash.landing":
        return "LANDING PAGE";
      case "dash.hello":
        return "Change this";
      case "dash.stations":
        return "Weather Stations";
      case "dash.precipitation":
        return "Precipitation";
      case "dash.register":
        return "Register New Member";
      default:
        return "DEFAULT TITLE " + this.$route.name;
    }
  }
}
